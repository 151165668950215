/* General Layout */
.App {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Section Styling */
section {
  max-width: 800px; /* Adds white space on both sides */
  margin: 0 auto;
  padding: 2rem 1rem;
}

/* Navbar */
.navbar {
  background-color: #ff4d4d;
  padding: 1rem;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.navbar-links {
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.navbar-links li {
  margin: 0 1rem;
}

.navbar-links a {
  color: white;
  text-decoration: none;
  font-size: 1rem;
  font-weight: bold;
  transition: color 0.3s;
}

.navbar-links a:hover {
  color: #ffe6e6;
}

/* Profile Section */
.profile-section {
  text-align: center;
  background-color: #ffe6e6;
}

.profile-picture {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  object-fit: cover;
  margin-bottom: 1rem;
}

.profile-section h1 {
  font-size: 2rem;
  color: #ff4d4d;
  margin-bottom: 0.5rem;
}

.profile-section p {
  font-size: 1rem;
  color: #333;
}

/* Experience Sections */
.work-experience-section,
.professional-projects-section,
.other-projects-section {
  background-color: #f9f9f9;
  text-align: left;
}

.work-experience-section h2,
.professional-projects-section h2,
.other-projects-section h2 {
  text-align: center;
  font-size: 2rem;
  color: #ff4d4d;
  margin-bottom: 2rem;
}

.experience-item,
.project-item,
.other-experience-item {
  margin-bottom: 2rem;
  border-left: 4px solid #ff4d4d;
  padding-left: 1rem;
}

.experience-item h3,
.project-item h3,
.other-experience-item h3 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 0.5rem;
}

.experience-item p,
.project-item p,
.other-experience-item p {
  font-size: 1rem;
  color: #555;
}

.experience-item ul,
.project-item ul,
.other-experience-item ul {
  list-style: disc;
  margin-left: 1.5rem;
}

.experience-item ul li,
.project-item ul li,
.other-experience-item ul li {
  margin-bottom: 0.5rem;
}

/* Other Experience */
.other-experience {
  margin-top: 2rem;
  padding-top: 1rem;
  border-top: 1px solid #ddd;
}

.other-experience h3 {
  font-size: 1.3rem;
  color: #ff4d4d;
  margin-bottom: 1rem;
}

/* Skills Section */
.skills-section {
  text-align: center;
  background-color: #fff5f5;
}

.skills-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0.5rem;
}

.skill-item {
  font-size: 0.9rem;
  padding: 0.5rem 1rem;
  background-color: #ffcccc;
  color: #333;
  border-radius: 20px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.skill-item:hover {
  transform: scale(1.1);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

/* Contact Me Section */
.contact-me-section {
  text-align: center;
  background-color: #fff5f5;
}

.contact-me-section h2 {
  font-size: 2rem;
  color: #ff4d4d;
  margin-bottom: 1rem;
}

.contact-me-section p {
  font-size: 1rem;
  color: #555;
  margin-bottom: 1rem;
}

.contact-me-section a {
  color: #ff4d4d;
  font-weight: bold;
  text-decoration: none;
  transition: color 0.2s ease;
}

.contact-me-section a:hover {
  color: #cc0000;
}
